function fromDocumentSnapshot(doc) {
    let balance = {
        id: doc.id,
        concept: doc.data()['concept'],
        currency: doc.data()['currency'],
        date: doc.data()['date'],
        description: doc.data()['description'],
        responsible: doc.data()['responsible'],
        status: doc.data()['status'],
        type: doc.data()['type'],

        value: doc.data()['value'],
        uid: doc['uid'],
    }

    if (doc.data()['updatedAt'] != null) {
        balance.updatedAt = doc.data()['updatedAt'];
    } else {
        balance.updatedAt = null;
    }

    return balance;
}

export {
    fromDocumentSnapshot
}