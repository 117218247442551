function fromDocumentSnapshot(doc) {
    return {
        id: doc.id,
        name: doc.data()['name'],
        currency: doc.data()['currency'],
        balance: doc.data()['balance'],
        status: doc.data()['status'],
        uid: doc.data()['uid'],
    }
}

export {
    fromDocumentSnapshot
}