import { createRouter, createWebHistory } from 'vue-router'
import Pockets from '../views/Pockets.vue'
import Balances from '../views/Balances.vue'
import Auth from '../views/Auth.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/', redirect: '/pockets', meta: {
      authMode: 'AUTH'
    },
    exact: true
  },
  {
    path: '/pockets', component: Pockets, meta: {
      authMode: 'AUTH'
    }
  },
  {
    path: '/balances', component: Balances, meta: {
      authMode: 'AUTH'
    }
  },
  {
    path: '/auth', component: Auth, meta: {
      authMode: 'GUEST'
    }
  },
  {
    path: '/:notFound(.*)', component: NotFound, meta: {
      authMode: 'AUTH'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
