import firebase from 'firebase/app'
import { auth } from '../firebase'

const state = () => ({
    user: null,
    isAuthenticated: false,
    instance: auth
})

const getters = {
    user(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    userId(state) {
        return state.user.uid;
    },
    instance(state) {
        return state.instance;
    }
}

const mutations = {
    setUser(state, payload) {
        state.user = payload;
    },
    authenticate(state) {
        state.isAuthenticated = true;
    },
    unauthenticate(state) {
        state.isAuthenticated = false;
    }
}

const actions = {
    async login(context) {
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            const user = await auth.signInWithPopup(provider);
            context.commit('setUser', user.user);
            context.commit('authenticate');
        } catch (error) {
            console.log(error);
        }
    },
    async logout(context) {
        try {
            await auth.signOut();
            context.commit('setUser', null);
            context.commit('unauthenticate');
        } catch (error) {
            console.log(error);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}