<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item" exact>Pockets App</router-link>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': isActive }">
        <div class="navbar-start">
          <router-link
            class="navbar-item"
            active-class="is-active"
            to="/pockets"
            >Bolsillos</router-link
          >

          <router-link
            class="navbar-item"
            active-class="is-active"
            to="/balances"
            >Balances</router-link
          >
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <button
                class="button is-link is-inverted"
                v-if="!isAuthenticated"
                @click="login()"
              >
                <span class="icon"
                  ><img src="../assets/icons8-google.svg" alt="Google" />
                </span>
                <span>Iniciar Sesión</span>
              </button>
              <button
                class="button is-inverted is-danger"
                v-else
                @click="logout()"
              >
                <span class="icon"
                  ><img
                    src="../assets/icons8-shutdown.svg"
                    alt="Cerrar Sesión"
                  />
                </span>
                <span>Cerrar Sesión</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.replace('/auth');
    },
    async login() {
      await this.$store.dispatch("auth/login");
      this.$router.replace('/pockets');
    },
  },
};
</script>

<style lang="sass">
</style>