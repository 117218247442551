<template>
  <the-navbar></the-navbar>
  <the-welcome v-if="isAuthenticated"></the-welcome>
  <router-view />
</template>

<script>
import TheNavbar from "./components/TheNavar";
import TheWelcome from "./components/TheWelcome";

export default {
  components: {
    TheNavbar,
    TheWelcome
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>

<style lang="scss">
$navbar-height: 5rem;

@import "bulma/sass/utilities/_all";
@import "bulma/sass/base/_all";
@import "bulma/sass/elements/_all";
@import "bulma/sass/form/_all";
@import "bulma/sass/components/_all";
@import "bulma/sass/grid/_all";
@import "bulma/sass/helpers/_all";
@import "bulma/sass/layout/_all";
</style>
