<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card p-3">
      <header class="modal-card-head">
        <p class="modal-card-title">Agregar Movimiento</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Fecha</label>
            <div class="control">
              <input
                v-model="balance.date"
                class="input"
                type="date"
                placeholder="mm/dd/aaaa"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Concepto</label>
            <div class="control">
              <input
                v-model="balance.concept"
                class="input"
                type="text"
                placeholder="Concepto"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Descripcion</label>
            <div class="control">
              <textarea
                v-model="balance.description"
                rows="3"
                class="textarea"
                placeholder="Descripcion del movimiento."
              ></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Tipo de Movimiento</label>

            <div class="select">
              <select v-model="balance.type">
                <option value="INCOME">Ingreso</option>
                <option value="DEBT">Egreso</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label">Divisa</label>

            <div class="select">
              <select v-model="balance.currency">
                <option value="COP">Peso Colombiano (COP)</option>
                <option value="USD">Dolar Americano (USD)</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Valor</label>
            <div class="control">
              <input
                v-model.number="balance.value"
                class="input"
                type="number"
                placeholder="0"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Responsable</label>
            <div class="control">
              <input
                v-model="balance.responsible"
                class="input"
                type="text"
                placeholder="Responsable"
              />
            </div>
          </div>

          <div class="field">
            <label class="checkbox">
              <input v-model="balance.status" type="checkbox" />
              Recibido?
            </label>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveBalance()">
          Guardar
        </button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  props: ["currentBalance"],
  emits: ["close"],
  data() {
    return {
      balance: {
        id: null,
        concept: "",
        description: "",
        currency: "COP",
        date: null,
        responsible: null,
        value: 0,
        type: "INCOME",
        status: false,
      },
    };
  },
  created() {
    if (this.currentBalance) {
      this.balance = { ...this.currentBalance };
      this.balance.date = format(
        this.currentBalance.date.toDate(),
        "yyyy-MM-dd"
      );
    }
  },
  methods: {
    async saveBalance() {
      try {
        let balance = {
          id: this.balance.id,
          data: {
            concept: this.balance.concept,
            description: this.balance.description,
            currency: this.balance.currency,
            date: parseISO(this.balance.date),
            responsible: this.balance.responsible,
            value: this.balance.value,
            type: this.balance.type,
            status: this.balance.status,
          },
        };
        await this.$store.dispatch("balances/save", balance);
        this.$emit("close");
      } catch (error) {
        console.log("Error while saving:", error);
      }
    },
  },
};
</script>

<style>
</style>