<template>
  <pocket-form :currentPocket="currentPocket" v-if="open" @close="close"></pocket-form>
  <div class="container p-3">
    <div class="columns">
      <div class="column is-half">
        <div class="box">
          <p
            class="title is-5 is-flex is-flex-direction-row is-align-items-center"
          >
            <img src="../assets/icons8-wallet.svg" width="50" alt="" />
            Bolsillos
          </p>
          <nav class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Total COP</p>
                <p class="title">{{ copBalance }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Total USD</p>
                <p class="title">{{ usdBalance }}</p>
              </div>
            </div>
          </nav>
          <div class="is-flex is-flex-direction-row is-justify-content-center">
            <button class="button is-primary is-outlined" @click="showForm()">
              Agregar Bolsillo
            </button>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <empty v-if="pockets.length == 0"></empty>
        <div v-else class="box" v-for="pocket in pockets" :key="pocket.id">
          <div class="columns is-mobile">
            <div class="column is-one-fifth has-text-centered">
              <img
                src="../assets/icons8-checkmark.svg"
                v-if="pocket.status"
                title="Recibido"
                width="34"
                alt=""
              />
              <img
                src="../assets/icons8-clock.svg"
                v-else
                width="34"
                title="Pendiente"
                alt=""
              />
            </div>
            <div class="column is-three-fifths">
              <p class="has-text-weight-semibold">
                {{ formatBalance(pocket) }}
                <span class="is-italic is-size-7">({{ pocket.currency }})</span>
              </p>
              <p>{{ pocket.name }}</p>
            </div>
            <div class="column is-one-fifth">
              <img
                src="../assets/icons8-edit.svg"
                width="28"
                alt=""
                class="ml-1 is-clickable"
                @click="showForm(pocket)"
              />
              <img
                src="../assets/icons8-remove.svg"
                width="28"
                alt=""
                class="ml-1 is-clickable"
                @click="remove(pocket.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import numeral from "numeral";
import PocketForm from '../components/PocketForm.vue';

export default {
  created() {
    this.$store.dispatch("pockets/load");
  },
  components: {
    PocketForm
  },
  data() {
    return {
      currentPocket: null,
      open: false,
    }
  },
  computed: {
    ...mapGetters({
      usdBalance: "pockets/usdBalance",
      copBalance: "pockets/copBalance",
      pockets: "pockets/pockets",
    }),
  },
  methods: {
    formatBalance(pocket) {
      if (pocket.currency === "USD") {
        return numeral(pocket.balance).format("$ 0,0[.]00");
      } else {
        return numeral(pocket.balance).format("$ 0,0");
      }
    },
    remove(pocketId) {
      this.$store.dispatch("pockets/delete", pocketId);
    },
    showForm(pocket = null) {
      this.open = true;
      this.currentPocket = pocket;
    },
    close() {
      this.open = false;
      this.currentPocket = null;
    }
  },
  beforeUnmount() {
    this.$store.dispatch('pockets/unsubscribe');
  }
};
</script>

<style>
</style>