import { db, firebase } from '../firebase'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import { fromDocumentSnapshot } from './models/Balances'

function setStart(date = null) {
    if (!date) {
        return startOfMonth(new Date());
    } else {
        return startOfDay(date);
    }
}

function setEnd(date = null) {
    if (!date) {
        return endOfMonth(new Date());
    } else {
        return endOfDay(date);
    }
}

const state = () => ({
    balances: [],
    _rawBalances: [],
    currency: 'COP',
    _subscription: null
})

const getters = {
    balances(state) {
        return state.balances;
    },
    income(state) {
        let income = 0;
        state.balances.filter(balance => balance.type == 'INCOME').forEach(balance => {
            income += balance.value;
        })
        return income;
    },
    debt(state) {
        let debt = 0;
        state.balances.filter(balance => balance.type == 'DEBT').forEach(balance => {
            debt += balance.value;
        })
        return debt;
    },
    currency(state) {
        return state.currency;
    },
    _rawBalances(state) {
        return state._rawBalances;
    },
    _subscription(state) {
        return state._subscription;
    }
}

const mutations = {
    setBalances(state, payload) {
        state.balances = payload;
    },
    setRaw(state, rawBalances) {
        state._rawBalances = rawBalances;
    },
    setBalance(state, payload) {
        state[payload.type] = payload.val;
    },
    changeCurrency(state, payload) {
        state.currency = payload;
    },
    setSubscription(state, sub) {
        state._subscription = sub;
    }
}

const actions = {
    load(context, dates = null) {
        const start = dates ? setStart(dates.start) : setStart();
        const end = dates ? setEnd(dates.end) : setEnd();

        const sub = db.collection('balances')
            .orderBy('date')
            .where('uid', '==', context.rootGetters['auth/userId'])
            .where('date', '>=', start)
            .where('date', '<=', end)
            .onSnapshot((snap) => {
                const balances = snap.docs.map((doc) => {
                    return fromDocumentSnapshot(doc);
                });

                context.commit('setRaw', balances);
                context.dispatch('filterBalances');
            })

            context.commit('setSubscription', sub);
    },
    filterBalances(context) {
        const filteredBalances = context.getters._rawBalances.filter(balance => balance.currency == context.getters.currency);
        context.commit('setBalances', filteredBalances);
    },
    changeCurrency(context, currency) {
        context.commit('changeCurrency', currency);
        context.dispatch('filterBalances');
    },
    unsubscribe(context) {
        context.getters._subscription();
    },
    async save(context, balance) {
        try {
            if (balance.id) {
                balance.data.uid = context.rootGetters['auth/userId'];
                balance.data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                await db.collection('balances').doc(balance.id).update(balance.data);
            } else {
                balance.data.uid = context.rootGetters['auth/userId'];
                await db.collection('balances').add(balance.data);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async delete(context, balanceId) {
        try {
            await db.collection('balances').doc(balanceId).delete();
        } catch (error) {
            console.log(error);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}