import { db } from '../firebase'
import { fromDocumentSnapshot } from './models/Pocket'
import numeral from "numeral";

const state = () => ({
    pockets: [],
    usdBalance: 0,
    copBalance: 0,
    _subscription: null
})

const getters = {
    pockets(state) {
        return state.pockets;
    },
    usdBalance(state) {
        return numeral(state.usdBalance).format('$ 0,0[.]00');
    },
    copBalance(state) {
        return numeral(state.copBalance).format('$ 0,0');
    },
    _subscription(state) {
        return state._subscription;
    }
}

const mutations = {
    setPockets(state, payload) {
        state.pockets = payload;
    },
    setBalance(state, payload) {
        state[payload.type] = payload.val;
    },
    setSubscription(state, sub) {
        state._subscription = sub;
    }
}

const actions = {
    load(context) {
        const sub = db.collection('pockets')
            .orderBy('balance', 'desc')
            .orderBy('currency')
            .where('uid', '==', context.rootGetters['auth/userId'])
            .onSnapshot((snap) => {
                const pockets = snap.docs.map((doc) => {
                    return fromDocumentSnapshot(doc);
                });

                let cop = 0;
                pockets.filter((pocket) => pocket.currency == 'COP').forEach((pocket) => {
                    cop += pocket.balance;
                })

                let usd = 0;
                pockets.filter((pocket) => pocket.currency == 'USD').forEach((pocket) => {
                    usd += pocket.balance;
                })

                context.commit('setPockets', pockets);
                context.commit('setBalance', { type: 'copBalance', val: cop });
                context.commit('setBalance', { type: 'usdBalance', val: usd });
            })
        context.commit('setSubscription', sub);
    },
    async save(context, pocket) {
        try {
            if (pocket.id) {
                pocket.data.uid = context.rootGetters['auth/userId'];
                await db.collection('pockets').doc(pocket.id).update(pocket.data);
            } else {
                pocket.data.uid = context.rootGetters['auth/userId'];
                await db.collection('pockets').add(pocket.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async delete(context, pocketId) {
        try {
            await db.collection('pockets').doc(pocketId).delete();
        } catch (error) {
            console.log(error);
        }
    },
    unsubscribe(context) {
        context.getters._subscription();
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}