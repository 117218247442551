<template>
  <balance-form :currentBalance="currentBalance" v-if="open" @close="close"></balance-form>
  <div class="container p-3">
    <div class="columns">
      <div class="column is-half">
        <div class="box">
          <div
            class="title is-5 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
          >
            <p class="is-flex is-flex-direction-row is-align-items-center">
              <img
                src="../assets/icons8-investment-portfolio.svg"
                width="50"
                alt=""
              />
              Balances
            </p>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  value="COP"
                  name="foobar"
                  :checked="currency == 'COP'"
                  @change="updateCurrency"
                />
                COP
              </label>
              <label class="radio">
                <input
                  type="radio"
                  value="USD"
                  name="foobar"
                  :checked="currency == 'USD'"
                  @change="updateCurrency"
                />
                USD
              </label>
            </div>
          </div>

          <div
            class="is-flex is-flex-direction-row is-justify-content-center"
            v-if="income > 0 || debt > 0"
          >
            <apexchart
              width="300"
              type="donut"
              :options="chartOptions"
              :series="[income, debt]"
            ></apexchart>
          </div>
          <div class="is-flex is-flex-direction-row is-justify-content-center">
            <button class="button is-primary is-outlined" @click="showForm()">
              Agregar Movimiento
            </button>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <div class="tabs is-fullwidth">
          <ul>
            <li
              :class="{ 'is-active': activeTab == 'INCOME' }"
              @click.prevent="activeTab = 'INCOME'"
            >
              <a>
                <span class="icon"
                  ><img src="../assets/icons8-profit.svg" alt=""
                /></span>
                <span>Ingresos</span>
              </a>
            </li>
            <li
              :class="{ 'is-active': activeTab == 'DEBT' }"
              @click.passive="activeTab = 'DEBT'"
            >
              <a>
                <span class="icon"
                  ><img src="../assets/icons8-coins.svg" alt=""
                /></span>
                <span>Egresos</span>
              </a>
            </li>
          </ul>
        </div>
        <empty v-if="balances.length == 0"></empty>
        <div v-else class="box" v-for="balance in balances" :key="balance.id">
          <div class="columns is-mobile">
            <div class="column is-one-fifth has-text-centered">
              <img
                src="../assets/icons8-checkmark.svg"
                v-if="balance.status"
                title="Pagado"
                width="34"
                alt=""
              />
              <img
                src="../assets/icons8-clock.svg"
                v-else
                width="34"
                title="Pendiente"
                alt=""
              />
            </div>
            <div class="column is-three-fifths is-clickable" @click="showDetails(balance.id)">
              <p class="has-text-weight-bold">
                {{ formatBalance(balance) }}
                <span class="is-italic is-size-7"
                  >({{ balance.currency }})</span
                >
              </p>
              <p>{{ balance.concept }}</p>
              <p>Fecha: {{ formatDate(balance.date.toDate()) }}</p>
              <div v-if="details == balance.id">
                <div><span class="has-text-weight-semibold">Descripcion</span>: {{ balance.description}}</div>
                <p><span class="has-text-weight-semibold">Responsable</span>: {{ balance.responsible}}</p>
              </div>
            </div>
            <div class="column is-one-fifth">
              <img
                src="../assets/icons8-edit.svg"
                width="28"
                alt=""
                class="ml-1 is-clickable"
                @click="showForm(balance)"
              />
              <img
                src="../assets/icons8-remove.svg"
                width="28"
                alt=""
                class="ml-1 is-clickable"
                @click="remove(balance.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import BalanceForm from "../components/BalanceForm.vue";
import { format } from 'date-fns';

export default {
  data() {
    return {
      chartOptions: {
        labels: ["Ingresos", "Egresos"],
        colors: ["#00BFA3", "#FF7070"],
        fill: {
          colors: ["#00BFA3", "#FF7070"],
        },
      },
      activeTab: "INCOME",
      currentBalance: null,
      open: false,
      details: null
    };
  },
  created() {
    this.$store.dispatch("balances/load");
  },
  computed: {
    income() {
      return this.$store.getters["balances/income"];
    },
    debt() {
      return this.$store.getters["balances/debt"];
    },
    currency() {
      return this.$store.getters["balances/currency"];
    },
    balances() {
      return this.$store.getters["balances/balances"].filter(
        (balance) => balance.type == this.activeTab
      );
    },
  },
  components: {
    BalanceForm
  },
  methods: {
    updateCurrency(event) {
      this.$store.dispatch("balances/changeCurrency", event.target.value);
    },
    showDetails(balanceId) {
      this.details = this.details == balanceId ? null : balanceId;
    },
    formatBalance(balance) {
      if (balance.currency === "USD") {
        return numeral(balance.value).format("$ 0,0[.]00");
      } else {
        return numeral(balance.value).format("$ 0,0");
      }
    },
    formatDate(date) {
      return format(date, "dd/MM/yyy")
    },
    remove(balanceId) {
      this.$store.dispatch("balances/delete", balanceId);
    },
    showForm(balance = null) {
      this.open = true;
      this.currentBalance = balance;
    },
    close() {
      this.open = false;
      this.currentBalance = null;
    },
  },
  beforeUnmount() {
    this.$store.dispatch("balances/unsubscribe");
  },
};
</script>

<style>
</style>