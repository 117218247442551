<template>
    <div class="container p-3">
      <div class="box ">
        <div class="columns is-mobile is-centered">
          <div class="column is-one-fifth-desktop is-one-fifth-mobile has-text-centered">
              <figure class="image">
              <img class="is-rounded" :src="$store.getters['auth/user'].photoURL" style="max-width: 128px; max-height: 128px" :alt="$store.getters['auth/user'].displayName">
              </figure>
          </div>
          <div class="column is-two-fifths-desktop is-four-fifths-mobile pl-5">
              <h1 class="title">Bienvenido {{ $store.getters['auth/user'].displayName }}</h1>
              <h2 class="is-size-6">Aquí puedes administrar tus bolsillos, ingresos y gastos.</h2>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
</style>