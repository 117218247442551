<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card p-3">
      <header class="modal-card-head">
        <p class="modal-card-title">Agregar Bolsillo</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Nombre</label>
            <div class="control">
              <input
                v-model="pocket.name"
                class="input"
                type="text"
                placeholder="Nombre del Bolsillo"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Balance</label>
            <div class="control">
              <input
                v-model.number="pocket.balance"
                class="input"
                type="number"
                placeholder="0"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Divisa</label>

            <div class="select">
              <select v-model="pocket.currency">
                <option value="COP">Peso Colombiano (COP)</option>
                <option value="USD">Dolar Americano (USD)</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="pocket.status" type="checkbox" />
              Recibido?
            </label>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="savePocket()">Guardar</button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentPocket"],
  emits: ["close"],
  data() {
    return {
      pocket: {
        id: null,
        name: "",
        currency: "COP",
        balance: 0,
        status: false,
      },
    };
  },
  created() {
    console.log("Called");
    if (this.currentPocket) {
      this.pocket = { ...this.currentPocket };
    }
  },
  methods: {
    async savePocket() {
      try {
        let pocket = {
          id: this.pocket.id,
          data: {
            name: this.pocket.name,
            currency: this.pocket.currency,
            balance: this.pocket.balance,
            status: this.pocket.status,
          },
        };
        await this.$store.dispatch("pockets/save", pocket);
        this.$emit("close");
      } catch (error) {
        console.log("Error while saving:", error);
      }
    },
  },
};
</script>

<style>
</style>