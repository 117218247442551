import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from "vue3-apexcharts";
import Empty from './components/Empty'
router.beforeEach(async (to, from, next) => {
    to
    from
    const user = await store.getters['auth/instance'].getCurrentUser();
    store.commit("auth/setUser", user);
    user ? store.commit("auth/authenticate") : store.commit("auth/unauthenticate");
    if (to.meta.authMode == 'AUTH' && !user) {
        next('/auth');
    } else if (to.meta.authMode == 'GUEST' && user) {      
        next('/');
    } else {
        next()
    }
});

const app = createApp(App)
app.component('Empty', Empty)
app.use(store)
app.use(router)
app.use(VueApexCharts);
app.mount('#app')